
html {
  background: url(images/triangles.jpg) center fixed no-repeat #000;
  background-size: cover;
}

/*
html {
  background: url(images/concrete_seamless.png) repeat;
}
*/

body {
  background: none;
  height: 100%;
}

#root {
  height: 100%;
}


.ui.attached.segment.overflow {
  overflow: initial;
}

main > .ui.attached.segment.actions {
  overflow: hidden;
}

main > .ui.attached {
  border-radius: 0 !important;
}

.ui.segment.actions button {
  margin-left: 1rem;
  margin: 0;
}

aside.vertical {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0px;
  bottom: 0px;
  left: 0px;
  width: 14rem;
  background: rgba(0, 0, 0, 0.7) none repeat scroll 0% 0%;
}

aside > .ui.inverted.menu {
  background: none;
  width: 100%;
}

aside.horizontal {
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #ccc;
  padding: 0 0.5rem;
}

aside .ui.avatar.image {
  margin: 0 0.75rem 0 0;
}

.ui.circular.right.floated.button {
  position: relative;
  margin-left: 0.5rem;
}

.ui.segment,
.ui.menu {
  box-shadow: none;
}

.ui.card,
.ui.cards > .card {
  box-shadow: 0 0 0 1px #d4d4d5;
}

a.link:hover {
  text-decoration: underline;
}

button.link:hover {
  color: #1e70bf;
  text-decoration: underline;
}

button.link, .acceptTermsLink {
  padding: 0;
  display: inline-block;
  cursor: pointer;
  border: none;
  color: #4183c4;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.acceptTermsLink {
  margin-left: .5em;
}

.pointer {
  cursor: pointer;
}

.small {
  font-size: smaller;
}

.float.right {
  float: right;
}

/*.ui.table.selectable tr, */
.ui.table th {
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.ui.table.selectable tr.disabled td {
  pointer-events: all;
}

.ui.table th i.icon {
  float: right;
}

.ui.table td.selected {
  background-color: rgb(249, 250, 251);
}

.ui.table .instalment input {
  text-align: center;
}

.ui.table .instalment {
  position: relative;
  overflow: visible;
}

.ui.table .instalment.deleted input, .ui.table .instalment.deleted .input {
  text-decoration: line-through;
}

.relative {
  position: relative;
}

.ui.table th.instalment i.icon {
  cursor: pointer;
}

i.icon.action {
  color: #4183c4;
  cursor: pointer;
}

.ui.header.block > .icon {
  display: inline-block;
  vertical-align: baseline;
  margin-right: .75rem;
}

span.dirty {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1rem;
  height: 1rem;
  color: #666;
}

.ui.table .instalment.focus input,
.ui.table .instalment input:focus {
  outline: #85b7d9 solid;
  outline-offset: 0.2rem;
}

.ui.input.instalmentValue > input {
  width: 5rem;
  text-align: right;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.placeholder {
  display: inline-block;
  background: #f0f0f0;
  min-width: 60%;
}

header .menu-container {
  margin: 1rem;
}

header .info-container {
  background: #f0f0f0;
}

.menu > .ui.selection.dropdown.item {
  border: 0;
  border-radius: 0;
}

.ui.table td.link {
  color: #1e70bf;
}

.ui.table th.error {
  background: #fff6f6 !important;
  color: #9f3a38 !important;
}

.ui.menu .item.purple.active,
.ui.menu .item.purple:hover {
  color: #fff !important;
  background: #b413ec;
}

.ui.menu .item.green.active,
.ui.menu .item.green:hover {
  color: #fff !important;
  background: #016936;
}

.ui.menu .item.orange.active,
.ui.menu .item.orange:hover {
  color: #fff !important;
  background: #fe9a76;
}

.ui.menu .item.teal.active,
.ui.menu .item.teal:hover {
  color: #fff !important;
  background: #008080;
}

.ui.menu.pagination,
.ui.menu.attached,
.ui.menu.attached .selection.item,
.ui.modal.active .header,
.ui.segment.actions {
  background: #f9fafb;
}

.ui.top.attached.red,
.ui.modal .red.header {
  border-top: 2px solid #b03060;
}

.ui.top.attached.purple,
.ui.modal .purple.header {
  border-top: 2px solid #b413ec;
}

.ui.top.attached.orange,
.ui.modal .orange.header {
  border-top: 2px solid #fe9a76;
}

.ui.top.attached.teal,
.ui.modal .teal.header {
  border-top: 2px solid #008080;
}

.ui.top.attached.green,
.ui.modal .green.header {
  border-top: 2px solid #016936;
}

.ui.top.attached.olive,
.ui.modal .olive.header {
  border-top: 2px solid #32cd32;
}

#root .Toastify__toast {
  border-radius: 0.3rem;
}

#root .Toastify__toast-body {
  padding-right: 1rem;
}

#root .Toastify__toast--default {
  background: #fff;
  color: #333;
}

#root .Toastify__toast--info {
  background: #3498db;
}

#root .Toastify__toast--success {
  background: #016936;
}

#root .Toastify__toast--warning {
  background: #f1c40f;
}

#root .Toastify__toast--error {
  background: #b03060;
}

.ui.segment {
  overflow: hidden;
}

.ui.segment .block {
  border-bottom: 1px dotted #e0e0e0;
  margin: 1rem 0rem;
  padding-bottom: 1.5rem;
}

.ui.segment .block:last-child {
  border-bottom: none;
  padding-bottom: 0rem;
}

.ui.pointing.secondary.attached.menu {
  margin-left: -1px;
}

.ui.secondary.pointing.attached.menu .item {
  margin-bottom: 0;
}

.ui.borderless.compact.bottom.attached.menu {
  overflow: hidden;
}

.pageSearch__input {
  min-width: 20rem;
}

.ui.loading.segment {
  height: 8rem;
}

.memberAvatarSelect__preview {
  margin-right: 1rem;
}

.memberAvatarSelect .avatarPicker {
  flex: 1;
  align-self: stretch;
}

.memberLink .ui.avatar.image {
  margin-right: 0.7rem;
}

i.icon.large {
  margin-right: 0.85rem;
}

.userInfo {
  min-width: 40rem;
}

span.marginRight {
  margin-right: 1rem;
}

.amount.positive {
  color: #016936;
}

.amount.negative {
  color: #b03060;
}

.amount.used {
  color: #aaa;
}
.amount.highlight {
  text-decoration: line-through;
}

.ui.input.amount > input,
div.amount {
  text-align: right;
}

.no-margin {
  margin: 0 !important;
}

.preformatted {
  white-space: pre;
}

.ui.cards {
  margin: 0;
}

/*
@red            : #B03060;
@orange         : #FE9A76;
@yellow         : #FFD700;
@olive          : #32CD32;
@green          : #016936;
@teal           : #008080;
@blue           : #0E6EB8;
@violet         : #EE82EE;
@purple         : #B413EC;
@pink           : #FF1493;
@brown          : #A52A2A;
@grey           : #A0A0A0;

*/

.color.red {
  color: #b03060;
}
.color.orange {
  color: #fe9a76;
}
.color.yellow {
  color: #ffd700;
}
.color.olive {
  color: #32cd32;
}
.color.green {
  color: #016936;
}
.color.teal {
  color: #008080;
}
.color.blue {
  color: #0e6eb8;
}
.color.violet {
  color: #ee82ee;
}
.color.purple {
  color: #b413ec;
}
.color.pink {
  color: #ff1493;
}
.color.brown {
  color: #a52a2a;
}
.color.grey {
  color: #a0a0a0;
}

.ui.search.dropdown span.sizer {
  color: rgba(255, 255, 255, 0);
  min-width: 2rem;
}

.ui.multiple.dropdown {
  padding-right: 1rem;
}

.ui.selection.dropdown > .dropdown.icon {
  top: auto;
}

.ui.label, .ui.label:first-child {
  margin: .14285714em
}